<template>
  <router-view />
</template>

<script>
import { CompanyUser } from '@/store/models'

export default {
  name: 'Users',
  mounted () {
    CompanyUser.api().get()
  }
}
</script>
